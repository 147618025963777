<template lang="pug">
  include ../plugins/pug/btn

  div.technologies.bg-black2
    div(
      ref="technologies__slider"
      class="swiper swiper__entry technologies__slider"
    )
      div.swiper-wrapper
        div(
          v-for="(item, index) in technologiesItems"
          :key="index"
        ).swiper-slide
          div.technologies__item
            div.technologies__icon
              img(
                :src="item.technologiesIcon"
                alt="social"
              )
            div.technologies__desc {{ item.technologiesDesc }}

    div.swiper-button-prev
    div.swiper-button-next
</template>

<script>
import Swiper, { Navigation } from 'swiper'
import 'swiper/swiper-bundle.min.css'
// images
import TechIcon1 from '@/assets/icons/tech-1.svg'
import TechIcon2 from '@/assets/icons/tech-2.svg'
import TechIcon3 from '@/assets/icons/tech-3.svg'
import TechIcon4 from '@/assets/icons/tech-4.svg'
import TechIcon5 from '@/assets/icons/tech-5.svg'
import TechIcon6 from '@/assets/icons/tech-6.svg'
import TechIcon7 from '@/assets/icons/tech-7.svg'
import TechIcon8 from '@/assets/icons/tech-8.svg'
import TechIcon9 from '@/assets/icons/tech-9.svg'
import TechIcon10 from '@/assets/icons/tech-10.svg'
import TechIcon11 from '@/assets/icons/tech-11.svg'
import TechIcon12 from '@/assets/icons/tech-12.svg'
import TechIcon13 from '@/assets/icons/tech-13.svg'
import TechIcon14 from '@/assets/icons/tech-14.svg'
import TechIcon15 from '@/assets/icons/tech-15.svg'
import TechIcon16 from '@/assets/icons/tech-16.svg'
import TechIcon17 from '@/assets/icons/tech-17.svg'
import TechIcon18 from '@/assets/icons/tech-18.svg'
import TechIcon19 from '@/assets/icons/tech-19.svg'
import TechIcon20 from '@/assets/icons/tech-20.svg'
export default {
  name: 'TechnologiesBlock',
  data () {
    return {
      technologiesItems: [
        {
          technologiesIcon: TechIcon1,
          technologiesDesc: 'Python'
        },
        {
          technologiesIcon: TechIcon2,
          technologiesDesc: 'JavaScript'
        },
        {
          technologiesIcon: TechIcon3,
          technologiesDesc: 'React & React Native'
        },
        {
          technologiesIcon: TechIcon4,
          technologiesDesc: 'Vue.js'
        },
        {
          technologiesIcon: TechIcon5,
          technologiesDesc: 'Django'
        },
        {
          technologiesIcon: TechIcon6,
          technologiesDesc: 'Node JS'
        },
        {
          technologiesIcon: TechIcon7,
          technologiesDesc: 'FastAPI'
        },
        {
          technologiesIcon: TechIcon8,
          technologiesDesc: 'Django REST framework'
        },
        {
          technologiesIcon: TechIcon9,
          technologiesDesc: 'Gitlab'
        },
        {
          technologiesIcon: TechIcon10,
          technologiesDesc: 'Kubernetes'
        },
        {
          technologiesIcon: TechIcon11,
          technologiesDesc: 'Docker'
        },
        {
          technologiesIcon: TechIcon12,
          technologiesDesc: 'Docker compose'
        },
        {
          technologiesIcon: TechIcon13,
          technologiesDesc: 'С++'
        },
        {
          technologiesIcon: TechIcon14,
          technologiesDesc: 'Arduino'
        },
        {
          technologiesIcon: TechIcon15,
          technologiesDesc: 'Raspberry Pi OS'
        },
        {
          technologiesIcon: TechIcon16,
          technologiesDesc: 'Linux based OS'
        },
        {
          technologiesIcon: TechIcon17,
          technologiesDesc: 'Elastic search'
        },
        {
          technologiesIcon: TechIcon18,
          technologiesDesc: 'TimescaleDB'
        },
        {
          technologiesIcon: TechIcon19,
          technologiesDesc: 'Redis'
        },
        {
          technologiesIcon: TechIcon20,
          technologiesDesc: 'Postgresql'
        }
      ]
    }
  },
  mounted () {
    new Swiper (this.$refs.technologies__slider, {
      modules: [Navigation],
      speed: 500,
      slidesPerView: 4,
      spaceBetween: 16,
      loop: true,
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev'
      },
      breakpoints: {
        576: {
          slidesPerView: 8,
          spaceBetween: 24
        },
        768: {
          slidesPerView: 10,
          spaceBetween: 32
        }
      }
    })
  }
}
</script>
